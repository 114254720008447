import Vue from 'vue'
import VueRouter from 'vue-router'
import rpc from '@/auth/rpc/ociRpc'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'check-in',
      component: () => import('@/views/CheckInOutView.vue'),
      meta: {
        pageTitle: 'Check In',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Check In',
            active: true,
          },
        ],
      },
    },
    {
      path: '/check-out',
      name: 'check-out',
      component: () => import('@/views/CheckInOutView.vue'),
      meta: {
        pageTitle: 'Check Out',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Check Out',
            active: true,
          },
        ],
      },
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('@/views/CheckInOutMap.vue'),
      meta: {
        pageTitle: 'Map',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/Projects.vue'),
      meta: {
        pageTitle: 'Projects',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        requiresLogin: true,
        breadcrumb: [],
      },
    },    
    {
      path: '/help',
      name: 'help',
      component: () => import('@/views/Help.vue'),
      meta: {
        pageTitle: 'Help',
        requiresLogin: true,
        breadcrumb: [],
      },
    },    
    {
      path: '/acceptreject',
      name: 'acceptreject',
      component: () => import('@/views/AcceptRejectView.vue'),
      meta: {
        pageTitle: 'Accept Reject',
        requiresLogin: true,
        breadcrumb: [],
      },
    },    
    {
      path: '/reject',
      name: 'reject',
      component: () => import('@/views/RejectView.vue'),
      meta: {
        pageTitle: 'Reject',
        requiresLogin: true,
        breadcrumb: [],
      },
    },     
    {
      path: '/acceptrejected',
      name: 'acceptrejected',
      component: () => import('@/views/AcceptRejectedView.vue'),
      meta: {
        pageTitle: 'Accept Rejected',
        requiresLogin: true,
        breadcrumb: [],
      },
    },     
    {
      path: '/acceptunaccepted',
      name: 'acceptunaccepted',
      component: () => import('@/views/AcceptUnacceptedView.vue'),
      meta: {
        pageTitle: 'Accept Rejected',
        requiresLogin: true,
        breadcrumb: [],
      },
    },      
    {
      path: '/completed',
      name: 'completed',
      component: () => import('@/views/CompletedView.vue'),
      meta: {
        pageTitle: 'Completed',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/noshow',
      name: 'noshow',
      component: () => import('@/views/NoShowView.vue'),
      meta: {
        pageTitle: 'No Show',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/unabletoattend',
      name: 'unabletoattend',
      component: () => import('@/views/UnableToAttendView.vue'),
      meta: {
        pageTitle: 'Unable to attend',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/job',
      name: 'job',
      component: () => import('@/views/Job.vue'),
      meta: {
        pageTitle: 'Job',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/reason',
      name: 'reason',
      component: () => import('@/views/ReasonView.vue'),
      meta: {
        pageTitle: 'Reason',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: () => import('@/views/CancelSignInView.vue'),
      meta: {
        pageTitle: 'Cancel',
        requiresLogin: true,
        breadcrumb: [],
      },
    },    
    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/googletest.vue'),
      meta: {
        pageTitle: 'Test',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/ethos',
      name: 'ethos',
      component: () => import('@/views/Ethos.vue'),
      meta: {
        pageTitle: 'Ethos',
        requiresLogin: true,
        breadcrumb: [],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
    return
  }
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (rpc.getToken() !== null && rpc.getToken().length) {
      next()
    } else {
      next({
        name: 'login',
      })
    }
  } else if (to.name === 'reset') {
    next()
  } else {
    next({
      name: 'login',
    })
  }
})

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (from) {
//     // nothing
//   }
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start()
//   }
//   next()
// })

// router.afterEach((to, from) => {
//   if (to || from) {
//     // nothing
//   }

//   // Complete the animation of the route progress bar.
//   NProgress.done()
// })

export default router
