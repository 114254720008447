import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import IdleVue from 'idle-vue'
import './registerServiceWorker'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Idle timeout
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000, // 300000 5 minutes
  startAtIdle: false,
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.directive('scroll', (el, binding) => {
  let f = (evt) => {
    if (binding.value(evt, el)) {
      el.removeEventListener('scroll', f)
    }
  }

  el.addEventListener('scroll', f)
})

new Vue({
  router,
  store,
  methods: {
    handleScroll(e) {
      console.log('scroll', e, +new Date())
      return true
    }
  },  
  render: h => h(App),
}).$mount('#app')
