<template>
  <div
    class="overlay"
  >
    <b-card>
      <b-card-title>
        Session Logging Out
      </b-card-title>
      <b-card-text>
        You have been idle for a while. You will be logged out in {{ countdown }} seconds.
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'IdleView',
  components: {
    BCard,
    BCardText,
    BCardTitle,
  },
  data() {
    return {
      timeBeforeLoggingOut: 30000, // 30 seconds
    }
  },
  computed: {
    countdown() {
      console.log('idle')
      return this.timeBeforeLoggingOut / 1000
    },
  },
  created() {
    const timerId = setInterval(() => {
      this.timeBeforeLoggingOut -= 1000
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)

      if (this.timeBeforeLoggingOut < 1) {
        clearInterval(timerId)
        this.$store.dispatch('app/logoutAndReset')
      }
    }, 1000)
  },
}
</script>

<style lang="postcss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
