import axios from '@axios'
import OciRpcService from '@/auth/rpc/ociRpcService'

const rpc = new OciRpcService(axios, {
  loginEndpoint: '/token/',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/tokenupdate/',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageUserIdKeyName: 'userid',
  storageTokenKeyName: 'access',
  storageWebTokenKeyName: 'webToken',
  storageRefreshTokenKeyName: 'refresh',
})

export default rpc
