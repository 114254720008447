import NProgress from 'nprogress'
import ociRpcDefaultConfig from '@/auth/rpc/ociRpcDefaultConfig'

export default class ociRpcService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  rpcConfig = { ...ociRpcDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, rpcOverrideConfig) {
    this.axiosIns = axiosIns
    this.rpcConfig = { ...this.rpcConfig, ...rpcOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        // start progress bar
        NProgress.start()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.rpcConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        // End progress bar
        NProgress.done()
        return response
      },
      error => {
        // End progress bar
        NProgress.done()
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              if (r.data.access !== undefined) {
                this.setToken(r.data.access)
              }
              if (r.data.refresh !== undefined) {
                this.setRefreshToken(r.data.refresh)
              }

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.rpcConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        // window.location.href = '/login'
        // this.setRefreshToken(null)
        // this.onAccessTokenFetched(null)
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getUserId() {
    return localStorage.getItem(this.rpcConfig.storageUserIdKeyName)
  }

  getWebToken() {
    return localStorage.getItem(this.rpcConfig.storageWebTokenKeyName)
  }

  getToken() {
    return localStorage.getItem(this.rpcConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.rpcConfig.storageRefreshTokenKeyName)
  }

  setUserId(value) {
    localStorage.setItem(this.rpcConfig.storageUserIdKeyName, value)
  }

  setWebToken(value) {
    localStorage.setItem(this.rpcConfig.storageWebTokenKeyName, value)
  }

  setToken(value) {
    localStorage.setItem(this.rpcConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.rpcConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.rpcConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.rpcConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    const refreshToken = this.getRefreshToken()
    const params = JSON.stringify({ refresh: refreshToken })
    return this.axiosIns.post(this.rpcConfig.refreshEndpoint, params)
  }
}
